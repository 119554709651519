/* ============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 1350px) { 

/* global
----------------------------------------------------------------------------------------------*/

.btn-plus {
	span {left: 100%; opacity: 1;}
}

/* header
----------------------------------------------------------------------------------------------*/
header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	nav {display: none;}
	.search {display: none;}
	.lang {display: none;}
	.right {
		.account-nav {
      height: 66px;
			.drop {visibility: visible;opacity: 1; display: none; @include transition(none);}
			&:hover{
                .drop{visibility: visible;opacity: 1;@include transition(none);}
            }
		}
		a {
			&.btn {
				margin-left: 0; 
			}
		}
	}
	.logo {
		margin-top: 0; 
	}
	.header-right {		 
		.header-top {
			margin-top: 0;
		}
		.header-bottom {
			display: none; 
		}
	}
}

.side-menu {
	.lang {display: block;}
} 

/* homepage
----------------------------------------------------------------------------------------------*/

.banner-home {
	.wrap-caption {width: auto; margin-left: 40px;}
	.caption {
		top: auto; bottom: 40px; height: auto;
		h1,h2 {font-size: 46px; line-height: 48px;}
	}
	&.banner-std {
		.location-right {
			width: 345px; padding: 50px 22px;
			h4 {margin-bottom: 35px;}
		}
		.map-location {padding-right: 345px;}
	}
	&.location {
		height: 600px;
		> img {display: block; width: 100%; height: 100%; object-fit: cover;}
	}
}

.home-sec {
	.wrapper {width: 980px;}
	&.h-about {
		max-width: 980px; background-size: 370px !important;
		.right {
			padding-right: 0; width: 600px;
		}
		.list-col {
			.col {margin: 0 30px 30px 0;}
		}
	}
	&.h-prod {
		padding: 70px 0;
		&:before {left: 20px;}
		.wrapper {width: 980px;}
		.slider {
			margin-left: 28px;  width: 545px;
			.box{
				padding: 0; margin: 0 8px;
				&:after {left: 0; right: 0;}
			}
			.text {
				left: 20px; right: 20px; text-align: center; width: auto;
				.btn-plus {
					position: relative; left: -28%;
					span {opacity: 1; left: 100%;}
				}
			}
		}
		.right {
			padding-top: 60px; padding-right: 10px;
			p {max-height: 74px; overflow: hidden;}
		}
		.slick-arrow {
			&.slick-next {right: -16px;}
			&.slick-prev {left: -16px;}
		}
	}
	&.h-updates {
		.list {
			.box {
				width: 30%; margin-right: 5%;
				&:nth-of-type(3n) {margin-right: 0;}
				&:nth-of-type(4) {display: none;}
				h6 {margin-bottom: 40px;}

			}
		}
	}
	&.h-testi {
		padding-bottom: 60px; margin-bottom: 60px;
		.box {
			margin: 0 8px;
			.img { width: 185px; height: 200px;}
			.text {
				width: 255px; margin-left: 20px;
				p {margin-bottom: 0;}
			}
		}
	}
	&.h-contact {
		.left, .right {width: 48.5%}
		.left #home-map {height: 550px;}
	}
}

.box-form {
	.row {
		.col { width: 220px; }
		&.col-3 {
			.col {width: 29%;}
		}
	}
}



/* middle
----------------------------------------------------------------------------------------------*/


.content-std {
	padding-top: 100px;
	.wrapper {width: 980px;}
	p {
		&.sub {line-height: 26px;}
	}
	&.about {
		padding: 155px 0;
		.side-image {
			width: 235px; 
			.img {
				width: 450px; left: -240px;height: 675px;
				img {position: relative;  display: block; object-fit: cover; height: 100%; width: 100%; max-width: none;}
			}
		}
		.inner-about {
			width: 51%;
		}
		.list-col {
			.col {width: 232px; margin-right: 35px;}
		}
	}
	.list-prod {
		margin-top: 60px;
		.box {
			width: 31%; margin-right: 3.5%;
			.text {padding: 25px 16px 30px;}
		}
	}
	.service-room-list {
		margin: 50px -30px;
		.box {
			.text {width: 355px;}
		}
	}

	&.content-detail {
		ol , ul , li, p {font-size: 16px; line-height: 26px;}
		.image-slider,.image-content {margin: 0 -30px 50px;}
		hr {margin: 60px -30px;}
		.nav-sub {margin-bottom: 60px;}
		.list-gallery {
			margin: 0 -35px;
			.box {
				h5 {font-size: 20px;}
			}
		}
		.box-inquiry {margin: 50px -35px;}
	}
	&.buy-step {
		.wrapper {width: 980px;}
		.wrap-step {
			margin-bottom: 100px;
			.box {
				width: 25%;
				h6 {padding: 0 32px;}
			}
		}
		.box-form {margin-top: 100px;}
		.left-content {
			width: 680px;
			h3 {margin: 55px 0 35px;}
			.box-form {
				width: 665px;
				.col {
					width: 45%; margin-right: 10%;
					&:last-of-type {margin-right: 0;}
				}
				.row {
					&.col-3 {
						.col {
							width: 31%; margin-right: 3.5%;
							&:nth-of-type(3) {margin-right: 0;}
						}
					}
				}
			}
		}
		.btn-next {
			.btn-block {width: auto; padding: 0 30px; min-width: 150px;}
		}
		.payment-option {
			a {width: 320px;}
		}
		.payment-detail {
			p {font-size: 16px; line-height: 26px;}
		}
		.box-red {
			.det-info {
				h3 {font-size: 24px; }
			}
		}
	}
	.tbl-std {
		tbody {
			tr {
				td {padding: 25px 10px;}
			}
		}
		a.delete {width: 35px;}
		&.table-history {margin-bottom: 0;}
	}
	.scroll_tbl {overflow: auto;}
	.counter-num {
		width: 52px;
		input {font-size: 14px; padding: 0 25px 0 8px;}
	}
	.price-type {
		.box {
			width: 23.25%; margin-right: 2%;
			.head {
				height: 100px;
				h4 {font-size: 22px;}
				small {font-size: 12px;}
			}
			.price-box {margin-bottom: 20px;}
			small {font-size: 12px;}
			h5 {font-size: 18px;} 
		}
	}
	.support-facility {
		.left, .right {float: none; display: block;}
		.left {width: 750px;}
		.right {margin-top: -50px;}
	}
	.box-contact {
		.left {width: 35%; @include boxSizing(border-box); padding: 70px 20px; min-height: 750px;}
		.right {width: 65%; padding: 60px 30px;@include boxSizing(border-box);}
		.box-form {
			.row {
				.col { 
					width: 47%; margin-right: 6%; 
					&:last-of-type {margin-right: 0;}
				}
			}
		}
	}
	.list-location {
		.box {
			.img {width: 49%;}
			.text {
				width: 50%; padding: 50px 30px 0;
				p {max-height: 74px; overflow: hidden; margin-bottom: 25px;}
			}
		}
	}
	.box-register {
		.left {
			width: 355px;
			img {width: 240px;}
		}
		.right {width: 620px; @include boxSizing(border-box); padding: 60px;}
		.box-form {
			.row {
				&.row-btn {
					p {margin-bottom: 0;}
				}
			}
		}
	}
	&.content-detail {
		.list-gallery {
			.box {
				width: 32%;margin-right: 2%; height: auto;
				&:nth-of-type(3n) {margin-right: 0;}
			}
		}
	}
	&.account-page {
		.wrapper {margin-bottom: 0;}
	}
}

.opt-checkbox{
	&.md{
		.item{
			label{padding: 8px 30px;}
		}
	}
}

.thanks-page {
	width: 980px;
	.text {
		padding: 80px 100px;
		p {font-size: 20px; line-height: 30px;}
	}
} 
.pg_404 {
	width: 980px; margin-top: 195px;
}

.src_result {width: 980px;}
.wrap_src {width: 730px;}
.box-share-list{
	.share-list{position: relative;top: auto;left: auto;}
}
.share-list{
	margin-bottom: 30px;
	a{display: inline-block;}
}

/* popup
----------------------------------------------------------------------------------------------*/
.wrap-popup {
	.box-popup {
		width: 820px;
		a.close {width: 55px; height: 55px; top: -25px;right: -25px; background-size: 25px;}
		.arrow-nav {
			width: 20px; background-size: 40px;
			&.next {right: -50px;}
			&.prev {left: -50px;}
		}
	}
}




/* footer
----------------------------------------------------------------------------------------------*/


footer {
	.wrapper {width: 980px;}
	.left {
		margin-top: 40px;
		nav {display: block; margin-bottom: 10px;}
	}
	nav {display: none;}
	.right {
		margin-top: 45px;
		.left {margin: 0;}
	}
}


} /* end media */


